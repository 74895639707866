import * as React from "react";
import { Link } from "gatsby";
import Footer from "../js/Footer";
import Navbar from "../js/Navbar";
import AiAcademyToast from "../js/layout/ai_academy_toast";
import "../styles/pages/404.scss";

// styles

// markup
const NotFoundPage = () => {
    return (
        <div className="notFoundPage-wrapper m-0 d-flex flex-column">
            <Navbar />
            <main className="notFoundPage text-center">
                <title>Not found</title>
                <div className="notFoundPage__title-error font-weight-bold">
                    404
                </div>
                <h1 className="notFoundPage__title text-muted text-uppercase">
                    Oops nothing was found
                </h1>
                <p className="text-muted">
                    The page you are looking for may have been removed, changed
                    its name or is temporarily unavailable{" "}
                    <Link to="/" className="notFoundPage__link">
                        Return to main page
                    </Link>
                </p>
            </main>
            <AiAcademyToast />
            <Footer />
        </div>
    );
};

export default NotFoundPage;
